import React from "react";
import SimulatorContainer from "../simulator-box/SimulatorContainer";
import Clock from "../../../assets/icons/clock-light.png";
import Money from "../../../assets/icons/money-light.png";
import "./Main.css";

const mobileMain = (
  <div className="main-container">
    <div className="text-main-container">
      <p className="title-main title-bold-main">
        Envía dinero<br></br>
        <span className="title-main">desde donde estés </span>{" "}
      </p>

      <p className="text-main">
        Con <span className="text-white-main">Celu</span>
        <span className="text-blue-main">pagos</span> el{" "}
        <span className="text-blue-main">envío</span> de{" "}
        <span className="text-blue-main">
          dinero <br></br>
        </span>{" "}
        nunca había sido tan <span className="text-blue-main">fácil</span> y{" "}
        <span className="text-blue-main">seguro</span>
      </p>
    </div>

    <div className="simulator-main-container">
      <SimulatorContainer />
    </div>

    <div className="cards-main-container">
      <div className="clock-main-container">
        <img src={Clock} alt="clock" className="clock-main" />
        <p className="text-main">
          Envía dinero en solo
          <span className="text-blue-main"> 30 minutos</span>
        </p>
      </div>
      <div className="money-main-container">
        <img src={Money} alt="money" className="money-main" />
        <p className="text-main">
          Con Celupagos envía dinero y{" "}
          <span className="text-blue-main">olvídate</span> de las{" "}
          <span className="text-blue-main">comisiones</span>
        </p>
      </div>
    </div>
  </div>
);

const desktopMain = (
  <div className="main-container">
    <div className="text-main-container">
      <p className="title-main title-bold-main">
        Envía dinero <br></br>{" "}
        <span className="title-main ">desde donde estés </span>{" "}
      </p>

      <p className="text-main">
        Con <span className="text-white-main">Celu</span>
        <span className="text-blue-main">pagos</span> el{" "}
        <span className="text-blue-main">envío</span> de{" "}
        <span className="text-blue-main">
          dinero <br></br>
        </span>{" "}
        nunca había sido tan <span className="text-blue-main">fácil</span> y{" "}
        <span className="text-blue-main">seguro</span>
      </p>
      <div className="cards-main-container">
        <div className="clock-main-container">
          <img src={Clock} alt="clock" className="clock-main" />
          <p className="text-main">
            Envía dinero en solo
            <span className="text-blue-main"> 30 minutos</span>
          </p>
        </div>
        <div className="money-main-container">
          <img src={Money} alt="money" className="money-main" />
          <p className="text-main">
            Con Celupagos envía dinero y{" "}
            <span className="text-blue-main">olvídate</span> de las{" "}
            <span className="text-blue-main">comisiones</span>
          </p>
        </div>
      </div>
    </div>

    <div className="simulator-main-container">
      <SimulatorContainer />
    </div>
  </div>
);

const Main = () => {
  return window.innerWidth < 580 ? mobileMain : desktopMain;
};

export default Main;
