import React, { ReactElement } from "react";
import CountrySelect from "./CountrySelect";
import { CountryOption } from "../../../utils/ConstHelper";

type Props = {
  onClick?: () => void;
  options: CountryOption[];
  value?: number | string;
  beneficiary?: boolean;
  onChangeValue?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCurrencyChange?: (value: any) => void;
  disabled: boolean;
  onBlur?: () => void;
  selectedCountry?: CountryOption;
  placeholder: string;
};

const SimulatorInput = ({
  onClick,
  options,
  value,
  beneficiary,
  onChangeValue,
  onCurrencyChange,
  disabled,
  onBlur,
  selectedCountry,
  placeholder
}: Props): ReactElement => {
  return (
    <div className="input-row">
      <input
        className="simulator-input"
        value={value}
        onChange={onChangeValue}
        disabled={disabled}
        onBlur={onBlur}
        placeholder={placeholder}
        maxLength={12}
      />
      <CountrySelect
        onClick={onClick}
        options={options}
        beneficiary={beneficiary}
        selectedCountry={selectedCountry}
        onCurrencyChange={onCurrencyChange}
      />
    </div>
  );
};

export default SimulatorInput;
