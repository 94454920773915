import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/img/logo/logoWhite.png";
import Yadio from "../../../assets/img/logo/yadio.png";
import Facebook from "../../../assets/icons/facebook.png";
import Instagram from "../../../assets/icons/instagram.png";
import Twitter from "../../../assets/icons/twitter.png";
import Youtube from "../../../assets/icons/youtube.png";
import "./Footer.css";

const FooterContainer = () => {
  return (
    <div>
      <div className="footer">
        <div className="logo-footer-container">
          <img src={Logo} alt="celupago" className="logo-footer" />
        </div>

        <div className="footer-wrap">
          <div className="footer-container">
            <h5 className="title-footer"> Aviso Legal</h5>
            <ul>
              <li>
                <div className="tooltip">
                  <span className="tooltiptext">Próximamente</span>
                  {/*  <Link to="/terms-of-use" target="_blank"> */}
                  <Link to="">Términos y condiciones</Link>
                </div>
              </li>
              <li>
                <div className="tooltip">
                  <span className="tooltiptext">Próximamente</span>
                  {/*  <Link to="/terms-of-use" target="_blank"> */}
                  <Link to=""> Permiso Legal</Link>
                </div>
              </li>
            </ul>
          </div>

          <div className="footer-container">
            <h5 className="title-footer">Soporte</h5>
            <a href="mailto:soporte@celupagos.com"> soporte@celupagos.com</a>
          </div>
          <div className="footer-container">
            <h5 className="title-footer">Horario</h5>
            <p className="week"> Lunes a viernes</p>
            <span className="schedule"> 8:00 a. m. - 5:00 p. m. </span>
            {/*   <p className="week"> Sábado</p>
          <span className="schedule"> 10:00AM - 3:00PM</span> */}
          </div>
          <div className="footer-container">
            <h5 className="title-footer">Síguenos</h5>
            <a
              rel="noopener noreferrer"
              href="https://www.facebook.com/envioscelupagos/"
              target="_blank"
            >
              <img src={Facebook} alt="facebook" className="brands facebook" />
            </a>
            <a
              rel="noopener noreferrer"
              href="https://twitter.com/envioscelupagos"
              target="_blank"
            >
              <img src={Twitter} alt="twitter" className="brands twitter" />
            </a>
            <a
              rel="noopener noreferrer"
              href="https://www.instagram.com/envioscelupagos/"
              target="_blank"
            >
              <img
                src={Instagram}
                alt="instagram"
                className="brands instagram"
              />
            </a>
            <a
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCj6dxFXf63p3Fx4NWV4esYw/?guided_help_flow=5"
              target="_blank"
            >
              <img src={Youtube} alt="youtube" className="brands youtube" />
            </a>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>

      <div className="allies-footer-container">
        <a rel="noopener noreferrer" href="https://yadio.io" target="_blank">
          <img src={Yadio} alt="yadio" className="yadio" />
        </a>
      </div>
    </div>
  );
};

export default FooterContainer;
