export const EMAIL_REGEX: string =
  '^(([^<>()\\[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

export const USER_NAME_REGEX: string = "([a-zA-Z0-9](_|-| )[a-zA-Z0-9])*";
export const PASSWORD_REGEX: string =
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$@$!%*?&.$($)$-$#_,-])[A-Za-z\\d$@$!%*?&#.$($)$-$_,-]{8,16}$";
export const ONLY_NUMBER_REGEX: string = "^[0-9]+$";

export const ONLY_LETTER_REGEX: string = "^[a-zA-Z]+$";

export const ONLY_LETTER_ACCENT_REGEX: string = "^[a-zA-ZñáéíóúÁÉÍÓÚ ]*$";

export type CountryOption = {
  id: number;
  flag: string;
  countryCurrency: string;
  value: string;
  label: string;
  countryName: string;
  iso: string;
};

export const FLAGS = {
  venFlag: require("../assets/country-flags/ven.png"),
  peruFlag: require("../assets/country-flags/peru.png"),
  argFlag: require("../assets/country-flags/arg.png"),
  bolFlag: require("../assets/country-flags/bol.png"),
  clpFlag: require("../assets/country-flags/clp.png"),
  colFlag: require("../assets/country-flags/col.png"),
  ecuFlag: require("../assets/country-flags/ecu.png")
};

export const COUNTRY_OPTIONS: CountryOption[] = [
  {
    id: 1,
    flag: FLAGS.venFlag,
    countryCurrency: "VES",
    value: "VES",
    label: "venezuela",
    countryName: "Venezuela",
    iso: "VE"
  },
  {
    id: 2,
    flag: FLAGS.peruFlag,
    countryCurrency: "PEN",
    value: "PEN",
    label: "peru",
    countryName: "Perú",
    iso: "PE"
  }
  // {
  //   id: 3,
  //   flag: FLAGS.argFlag,
  //   countryCurrency: "ARS",
  //   value: "ARS",
  //   label: "argentina",
  //   countryName: "Argentina",
  //   iso: "AR"
  // },
  // {
  //   id: 4,
  //   flag: FLAGS.clpFlag,
  //   countryCurrency: "CLP",
  //   value: "CLP",
  //   label: "chile",
  //   countryName: "Chile",
  //   iso: "CL"
  // },
  // {
  //   id: 5,
  //   flag: FLAGS.colFlag,
  //   countryCurrency: "COP",
  //   value: "COP",
  //   label: "colombia",
  //   countryName: "Colombia",
  //   iso: "CO"
  // },
  // {
  //   id: 6,
  //   flag: FLAGS.ecuFlag,
  //   countryCurrency: "USD",
  //   value: "USD",
  //   label: "ecuador",
  //   countryName: "Ecuador",
  //   iso: "EC"
  // }
];
