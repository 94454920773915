import React from "react";
import Lock from "../../../assets/icons/lock.png";
import File from "../../../assets/icons/file-dark.png";
import Link from "../../../assets/icons/link-dark.png";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <div className="aboutUs" id="aboutUs">
      <div className="title-aboutUs">NOSOTROS</div>

      <div className="file-aboutUs">
        <div className="wrap-aboutUs">
          <img src={File} alt="file" className="file" />
        </div>

        <div className="subTitle-wrap-aboutUs">
          <div className="file-subTitle-shadow-aboutUs"> </div>
          <p className="subTitle-aboutUs file-subTitle-aboutUs">
            <span className="text-light">Creamos </span>alianzas
          </p>
          <p className="text-aboutUs">
            Estamos asociados con más de 10 empresas de servicio y operamos con
            los principales bancos del país:{" "}
            <span className="text-dark">
              BBVA Provincial, Banesco y Mercantil
            </span>
          </p>
        </div>
      </div>

      <div className="link-aboutUs">
        <div className="wrap-aboutUs">
          <img src={Link} alt="link" className="link" />
        </div>

        <div className="subTitle-wrap-aboutUs">
          <div className="lock-subTitle-shadow-aboutUs"> </div>
          <p className="subTitle-aboutUs lock-subTitle-aboutUs">
            <span className="text-light"> Atendemos a más de</span> 90 mil
            clientes
          </p>

          <p className="text-aboutUs">
            Con el servicio de recargas móviles a través de operadoras
            reconocidas
          </p>
        </div>
      </div>
      <div className="lock-aboutUs">
        <div className="wrap-aboutUs">
          <img src={Lock} alt="lock" className="lock" />
        </div>

        <div className="subTitle-wrap-aboutUs">
          <div className="link-subTitle-shadow-aboutUs"> </div>
          <p className="subTitle-aboutUs link-subTitle-aboutUs">
            <span className="text-light"> Brindamos</span> confianza
          </p>

          <p className="text-aboutUs">
            Garantizando el abono de tu dinero, cumpliendo los mejores
            estándares de seguridad
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
