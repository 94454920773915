import React, { ReactElement } from "react";
import "./Common.css";

type Props = {
  disabled: boolean;
  buttonText: string;
  onClick?: () => void;
};

const Button = ({ disabled, buttonText, onClick }: Props): ReactElement => {
  return (
    <div className="button-container">
      <button
        type="button"
        className={disabled ? "common-button-disabled" : "common-button"}
        onClick={onClick}
        disabled={disabled}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default Button;
