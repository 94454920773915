import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";

const Menu = () => {
  return (
    <nav className="menu">
      <ul>
        <li>
          <a href="#aboutUs">Nosotros</a>
        </li>
        <li>
          <div className="tooltip">
            <Link to="/">Blog</Link>
            <span className="tooltiptext">Próximamente</span>
          </div>
        </li>
        <li>
          <div className="tooltip">
            <Link to="/">Iniciar sesión</Link>
            <span className="tooltiptext">Próximamente</span>
          </div>
        </li>
        <li>
          <div className="tooltip">
            <button className="register">Regístrate</button>
            <span className="tooltiptext">Próximamente</span>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Menu;
