import { SIMULATOR_ACTIONS } from "../actions/SimulatorActions";
import { CountryOption } from "../../utils/ConstHelper";
export type simulatorStateType = typeof INITIAL_STATE;

const INITIAL_STATE = {
  saving: "",
  savingCurrency: "",
  total: "" as number | undefined | string,
  totalCurrency: "",
  typeOfChange: "",
  amountToSend: "" as number | undefined | string,
  amountToSendCurrency: "",
  amountToReceive: "" as number | undefined | string,
  amountTorReceiveCurrency: "",
  rates: {},
  amountToSendCountry: undefined as CountryOption | undefined,
  amountToReceiveCountry: undefined as CountryOption | undefined,
  displayDetails: true,
  minAmount: "" as number | undefined | string,
  maxAmount: "" as number | undefined | string
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    //GET YADIO RATES

    case SIMULATOR_ACTIONS.SET_MIN_MAX_AMOUNT:
      return {
        ...state,
        maxAmount: action.payload.maxAmount,
        minAmount: action.payload.minAmount
      };

    case SIMULATOR_ACTIONS.SET_USER_LANDING_COUNTRY:
      return {
        ...state,
        amountToSendCountry: action.payload.amountToSendCountry,
        amountToSendCurrency: action.payload.amountToSendCurrency
      };

    case SIMULATOR_ACTIONS.GET_RATES:
      return {
        ...state,
        rates: action.payload.rates
        //amountToSendCurrency: action.payload.amountToSendCurrency
      };

    //AMOUNT TO SEND
    case SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_SEND:
      return { ...state, amountToSend: action.payload };

    case SIMULATOR_ACTIONS.SET_AMOUNT_TO_SEND_CURRENCY:
      return {
        ...state,
        amountToSendCurrency: action.payload.amountToSendCurrency,
        amountToSendCountry: action.payload.amountToSendCountry
      };

    //AMOUNT TO RECEIVE
    case SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_RECEIVE:
      return { ...state, amountToReceive: action.payload };

    case SIMULATOR_ACTIONS.SET_AMOUNT_TO_RECEIVE_CURRENCY:
      return { ...state, amountTorReceiveCurrency: action.payload };

    //SET TYPE OF CHANGE
    case SIMULATOR_ACTIONS.SET_TYPE_OF_CHANGE:
      return { ...state, typeOfChange: action.payload };

    //SET SAVING
    case SIMULATOR_ACTIONS.SET_SAVING:
      return { ...state, saving: action.payload };

    case SIMULATOR_ACTIONS.SET_SAVING_CURRENCY:
      return { ...state, savingCurrency: action.payload };

    //LOOK AND FEEL
    case SIMULATOR_ACTIONS.SHOW_DETAILS_BLOCK:
      return { ...state, displayDetails: action.payload };

    case SIMULATOR_ACTIONS.DO_NOTHING:
      return state;

    default:
      return state;
  }
};
