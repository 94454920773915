import React, { Component } from "react";
import { connect } from "react-redux";
import ReactGA from "react-ga";

import SimulatorInput from "./SimulatorInput";
import Button from "../Button";
import DetailButton from "./DetailButton";
import { COUNTRY_OPTIONS, CountryOption } from "../../../utils/ConstHelper";
import {
  getRates,
  onSendValueChange,
  onReceiveValueChange,
  showDetails,
  setAmountToSendCurrency,
  onReceiveValueBlur,
  onSendValueBlur,
  setInitialCountry,
  convertToNumber
} from "../../../ducks/actions/SimulatorActions";
import { globalStateType } from "../../../ducks/reducers";
import "./Simulator.css";

const defaultProps = {};
const defaultState = {};

type Props = {
  //props | state
  saving?: string;
  savingCurrency: string;
  total?: number | string;
  totalCurrency: string;
  typeOfChange: string;
  amountToSend?: number | string;
  amountToReceive?: number | string;
  displayDetails: boolean;
  rates: any;
  amountToSendCurrency: string;
  amountToSendCountry?: CountryOption;
  amountToReceiveCountry?: CountryOption;
  maxAmount?: number | string;
  minAmount?: number | string;
  //functions
  getRates: () => void;
  onSendValueChange: (value: number | string) => void;
  onReceiveValueChange: (value: number | string) => void;
  showDetails: (value: boolean) => void;
  setAmountToSendCurrency: (text: string) => void;
  onReceiveValueBlur: () => void;
  onSendValueBlur: () => void;
  setInitialCountry: () => void;
} & typeof defaultProps;

type State = typeof defaultState;

class SimulatorContainer extends Component<Props> {
  componentDidMount() {
    const { getRates } = this.props;

    getRates();
  }

  handleOnButtonClick = () => {
    ReactGA.event({
      category: "Button",
      action: "Click enviar dinero from landing page"
    });
    window.open("https://www.instagram.com/envioscelupagos/");
  };

  onCheckMarkChange = () => {
    const { showDetails, displayDetails } = this.props;
    showDetails(!displayDetails);
  };
  handleAmountToSendCurrencyChange = (value: any) => {
    const { setAmountToSendCurrency } = this.props;
    setAmountToSendCurrency(value.countryCurrency);
  };
  handleAmountToSendChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onSendValueChange } = this.props;
    onSendValueChange(event.target.value);
  };

  handleAmountToReceiveChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { onReceiveValueChange } = this.props;
    onReceiveValueChange(event.target.value);
  };

  getErrorMessage = () => {
    const {
      maxAmount,
      minAmount,
      amountToSend,
      amountToSendCurrency
    } = this.props;

    if (
      maxAmount &&
      amountToSend &&
      minAmount &&
      convertToNumber(amountToSend) < convertToNumber(maxAmount) &&
      convertToNumber(amountToSend) > convertToNumber(minAmount)
    ) {
      return <React.Fragment />;
    }

    if (
      minAmount &&
      amountToSend &&
      convertToNumber(amountToSend) < convertToNumber(minAmount)
    ) {
      return (
        <p className="simulator-alert-text">
          Puedes enviar desde {minAmount} {amountToSendCurrency}
        </p>
      );
    }
    if (
      maxAmount &&
      amountToSend &&
      convertToNumber(amountToSend) > convertToNumber(maxAmount)
    ) {
      return (
        <p className="simulator-alert-text">
          Puedes enviar hasta {maxAmount} {amountToSendCurrency}
        </p>
      );
    }
  };

  render() {
    const {
      saving,
      savingCurrency,
      //total,
      // totalCurrency,
      typeOfChange,
      amountToSend,
      amountToReceive,
      displayDetails,
      amountToSendCurrency,
      onReceiveValueBlur,
      onSendValueBlur,
      amountToSendCountry,
      maxAmount,
      minAmount
    } = this.props;
    return (
      <div className="simulator-container">
        <p className="simulator-text">
          Tú <span className="simulator-text-bold">envías</span>
        </p>
        <SimulatorInput
          value={amountToSend}
          options={COUNTRY_OPTIONS}
          onChangeValue={this.handleAmountToSendChange}
          onCurrencyChange={this.handleAmountToSendCurrencyChange}
          disabled={amountToSendCurrency ? false : true}
          onBlur={onSendValueBlur}
          selectedCountry={amountToSendCountry}
          placeholder={"Ingresa monto a enviar"}
        />
        {this.getErrorMessage()}
        <p className="simulator-text">
          Tu beneficiario <span className="simulator-text-bold">recibe</span>
        </p>
        <SimulatorInput
          value={amountToReceive}
          options={COUNTRY_OPTIONS}
          onChangeValue={this.handleAmountToReceiveChange}
          beneficiary
          disabled={amountToSendCurrency ? false : true}
          onBlur={onReceiveValueBlur}
          placeholder={"Monto a recibir"}
        />
        <div className="savings-container">
          <div className="savings-text-container">
            <p className="savings-text">
              Te ahorras {saving} {savingCurrency}
            </p>
          </div>

          <DetailButton onDetailPress={this.onCheckMarkChange} />
        </div>
        {displayDetails ? (
          <div className="resume-container">
            <p className="savings-text margin-none">
              Tipo de cambio: <strong>{typeOfChange}</strong>
            </p>
            <p className="savings-text margin-none">
              Llega en <strong>30 minutos</strong>
            </p>
            {/* <p className="savings-text margin-none">
              Costo total:{" "}
              <strong>
                {total} {totalCurrency}
              </strong>
            </p> */}
          </div>
        ) : (
          <React.Fragment />
        )}
        <Button
          buttonText={"ENVIAR DINERO"}
          onClick={this.handleOnButtonClick}
          disabled={
            maxAmount &&
            amountToSend &&
            minAmount &&
            convertToNumber(amountToSend) < convertToNumber(maxAmount) &&
            convertToNumber(amountToSend) > convertToNumber(minAmount)
              ? false
              : true
          }
        />
      </div>
    );
  }
}

const mapStateTopProps = (state: globalStateType) => {
  return {
    saving: state.simulatorBox.saving,
    savingCurrency: state.simulatorBox.savingCurrency,
    total: state.simulatorBox.total,
    totalCurrency: state.simulatorBox.totalCurrency,
    typeOfChange: state.simulatorBox.typeOfChange,
    amountToSend: state.simulatorBox.amountToSend,
    amountToReceive: state.simulatorBox.amountToReceive,
    rates: state.simulatorBox.rates,
    displayDetails: state.simulatorBox.displayDetails,
    amountToSendCurrency: state.simulatorBox.amountToSendCurrency,
    amountToSendCountry: state.simulatorBox.amountToSendCountry,
    amountToReceiveCountry: state.simulatorBox.amountToReceiveCountry,
    maxAmount: state.simulatorBox.maxAmount,
    minAmount: state.simulatorBox.minAmount
  };
};

export default connect(mapStateTopProps, {
  getRates,
  onSendValueChange,
  onReceiveValueChange,
  showDetails,
  setAmountToSendCurrency,
  onReceiveValueBlur,
  onSendValueBlur,
  setInitialCountry
})(SimulatorContainer);
