import React, { Component } from "react";
import HeaderContainer from "../common/header/HeaderContainer";
import AboutUs from "../common/body/AboutUs";
import FooterContainer from "../common/footer/FooterContainer";
import "./Landing.css";
import Main from "../common/body/Main";

class Landing extends Component {
  render() {
    return (
      <div className="landing-container">
        <main className="body-content">
          <div className="landing-header-container">
            <HeaderContainer />
          </div>

          <div className="landing-main-container">
            {" "}
            <Main />
          </div>

          <div className="landing-aboutUs-container">
            {" "}
            <AboutUs />
          </div>

          <div className="landing-footer-container">
            <FooterContainer />
          </div>
        </main>
      </div>
    );
  }
}

export default Landing;
