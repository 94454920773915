import axios from "axios";
//import RequestManager from "../../services/RequestManager";
import { COUNTRY_OPTIONS } from "../../utils/ConstHelper";
//const requestManager = new RequestManager();

const base = "SIMULATOR_ACTIONS_";

export const SIMULATOR_ACTIONS = {
  DO_NOTHING: base + "DO_NOTHING",
  GET_RATES: base + "GET_RATES",
  SET_SAVING: base + "SET_SAVING",
  SET_SAVING_CURRENCY: base + "SET_SAVING_CURRENCY",
  SET_TOTAL: base + "SET_TOTAL",
  SET_TOTAL_CURRENCY: base + "SET_TOTAL_CURRENCY",
  SET_TYPE_OF_CHANGE: base + "SET_TYPE_OF_CHANGE",
  SHOW_DETAILS_BLOCK: base + "SHOW_DETAILS_BLOCK",
  ON_CHANGE_AMOUNT_TO_SEND: base + "ON_CHANGE_AMOUNT_TO_SEND",
  SET_AMOUNT_TO_SEND_CURRENCY: base + "SET_AMOUNT_TO_SEND_CURRENCY",
  ON_CHANGE_AMOUNT_TO_RECEIVE: base + "ON_CHANGE_AMOUNT_TO_RECEIVE",
  SET_AMOUNT_TO_RECEIVE_CURRENCY: base + "SET_AMOUNT_TO_RECEIVE_CURRENCY",
  SET_USER_LANDING_COUNTRY: base + "SET_USER_LANDING_COUNTRY",
  SET_MIN_MAX_AMOUNT: base + "SET_MIN_MAX_AMOUNT"
};

const latinFormat = (value: number) => {
  const formatter = new Intl.NumberFormat("es-VE", {
    style: "currency",
    currency: "USD"
  });
  // console.log(formatter.format(value));

  return formatter.format(value).substr(4);
};

export const convertToNumber = (value: number | string) => {
  const regex = /([^0-9])+/gi;
  //const commaRegex = /,,*?(?=\d+?,)/gi;
  const stringValue = value.toString().split(",");
  const valueToConvert = stringValue[0].replace(regex, "").replace(",", ".");
  //  console.log("valueToConvert,valueToConvert", valueToConvert);
  const valueConverted = Number(valueToConvert);
  // console.log("valueConverted", valueConverted);
  return Number.isNaN(valueConverted) ? 0 : valueConverted;
};

export const setInitialCountry = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { rates } = state.simulatorBox;

    axios
      .get(process.env.REACT_APP_GET_COUNTRY_SERVICE + "")
      .then((response: any) => {
        // console.log(response.data.pais);
        const country = response.data.pais;
        const originCountry = COUNTRY_OPTIONS.filter(
          option => option.iso === country.iso
        );
        //console.log(originCountry);
        const maxVES = rates["USD"] * 150;
        const minVES = rates["USD"] * 12;
        if (
          originCountry.length === 0 ||
          (originCountry.length > 0 && originCountry[0].iso === "VE")
        ) {
          const minLocalCurrency =
            minVES / rates[COUNTRY_OPTIONS.slice(1)[0].countryCurrency];
          const maxLocalCurrency =
            maxVES / rates[COUNTRY_OPTIONS.slice(1)[0].countryCurrency];
          dispatch({
            type: SIMULATOR_ACTIONS.SET_MIN_MAX_AMOUNT,
            payload: {
              maxAmount: latinFormat(maxLocalCurrency * 0.915),
              minAmount: latinFormat(minLocalCurrency * 0.915)
            }
          });
          dispatch({
            type: SIMULATOR_ACTIONS.SET_USER_LANDING_COUNTRY,
            payload: {
              amountToSendCountry: COUNTRY_OPTIONS.slice(1)[0],
              amountToSendCurrency: COUNTRY_OPTIONS.slice(1)[0].countryCurrency
            }
          });
          dispatch(
            setTypeOfChange(
              COUNTRY_OPTIONS.slice(1)[0].countryCurrency,
              rates[COUNTRY_OPTIONS.slice(1)[0].countryCurrency]
                ? rates[COUNTRY_OPTIONS.slice(1)[0].countryCurrency]
                : 1
            )
          );
        } else {
          const minLocalCurrency =
            minVES / rates[originCountry[0].countryCurrency];
          const maxLocalCurrency =
            maxVES / rates[originCountry[0].countryCurrency];
          dispatch({
            type: SIMULATOR_ACTIONS.SET_MIN_MAX_AMOUNT,
            payload: {
              maxAmount: latinFormat(maxLocalCurrency * 0.915),
              minAmount: latinFormat(minLocalCurrency * 0.915)
            }
          });
          dispatch({
            type: SIMULATOR_ACTIONS.SET_USER_LANDING_COUNTRY,
            payload: {
              amountToSendCountry: originCountry[0],
              amountToSendCurrency: originCountry[0].countryCurrency
            }
          });
          dispatch(
            setTypeOfChange(
              originCountry[0].countryCurrency,
              rates[originCountry[0].countryCurrency]
                ? rates[originCountry[0].countryCurrency]
                : 1
            )
          );
        }
      });
  };
};

//LOOK AND FEEL
export const showDetails = (value: boolean) => {
  return {
    type: SIMULATOR_ACTIONS.SHOW_DETAILS_BLOCK,
    payload: value
  };
};

//AMOUNT TO SEND
export const onSendValueChange = (value: number | string) => {
  // const regex = /([^0-9])+/gi;
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { rates, amountToSendCurrency } = state.simulatorBox;
    if (value) {
      dispatch({
        type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_SEND,
        payload: convertToNumber(value)
      });
      dispatch({
        type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_RECEIVE,
        payload: latinFormat(
          convertToNumber(value) * rates[amountToSendCurrency] * 0.915
        )
      });
      dispatch(setSaving(convertToNumber(value)));
      dispatch(setSavingCurrency(amountToSendCurrency));
    } else {
      dispatch({
        type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_SEND,
        payload: ""
      });
      dispatch({
        type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_RECEIVE,
        payload: ""
      });
      dispatch(setSaving(0));
      dispatch(setSavingCurrency(amountToSendCurrency));
    }
  };
};

export const setAmountToSendCurrency = (value: string) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { rates, amountToSend } = state.simulatorBox;
    const country = COUNTRY_OPTIONS.filter(
      option => option.countryCurrency === value
    );
    const maxVES = rates["USD"] * 150;
    const minVES = rates["USD"] * 12;
    const minLocalCurrency = minVES / rates[country[0].countryCurrency];
    const maxLocalCurrency = maxVES / rates[country[0].countryCurrency];
    dispatch({
      type: SIMULATOR_ACTIONS.SET_MIN_MAX_AMOUNT,
      payload: {
        maxAmount: latinFormat(maxLocalCurrency * 0.915),
        minAmount: latinFormat(minLocalCurrency * 0.915)
      }
    });
    dispatch({
      type: SIMULATOR_ACTIONS.SET_AMOUNT_TO_SEND_CURRENCY,
      payload: { amountToSendCurrency: value, amountToSendCountry: country[0] }
    });

    dispatch(setTypeOfChange(value, rates[value]));
    if (amountToSend) {
      dispatch(setSaving(convertToNumber(amountToSend)));
      dispatch(setSavingCurrency(value));
      dispatch({
        type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_RECEIVE,
        payload: latinFormat(
          convertToNumber(amountToSend) * rates[value] * 0.915
        )
      });
    } else {
      dispatch(setSaving(convertToNumber(0)));
      dispatch(setSavingCurrency(value));
    }
  };
};

export const onSendValueBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { amountToSend } = state.simulatorBox;
    if (amountToSend) {
      dispatch({
        type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_SEND,
        payload: latinFormat(convertToNumber(amountToSend))
      });
    } else {
      dispatch({
        type: SIMULATOR_ACTIONS.DO_NOTHING
      });
    }
  };
};

//AMOUNT TO RECEIVE
export const onReceiveValueChange = (value: number | string) => {
  // const regex = /([^0-9])+/gi;
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { rates, amountToSendCurrency, amountToSend } = state.simulatorBox;
    if (value) {
      dispatch({
        type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_RECEIVE,
        payload: convertToNumber(value)
      });

      dispatch({
        type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_SEND,
        payload: latinFormat(
          convertToNumber(value) / (rates[amountToSendCurrency] * 0.915)
        )
      });
      dispatch(setSaving(convertToNumber(amountToSend)));
      dispatch(setSavingCurrency(amountToSendCurrency));
    } else {
      dispatch({
        type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_RECEIVE,
        payload: ""
      });

      dispatch({
        type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_SEND,
        payload: ""
      });
      dispatch(setSaving(0));
      dispatch(setSavingCurrency(amountToSendCurrency));
    }
  };
};

export const setAmountToReceiveCurrency = (value: string) => {
  return {
    type: SIMULATOR_ACTIONS.SET_AMOUNT_TO_RECEIVE_CURRENCY,
    payload: value
  };
};

export const onReceiveValueBlur = () => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const { amountToReceive } = state.simulatorBox;
    if (amountToReceive) {
      dispatch({
        type: SIMULATOR_ACTIONS.ON_CHANGE_AMOUNT_TO_RECEIVE,
        payload: latinFormat(convertToNumber(amountToReceive))
      });
    } else {
      dispatch({
        type: SIMULATOR_ACTIONS.DO_NOTHING
      });
    }
  };
};

//SET TYPE OF CHANGE

const setTypeOfChange = (currency: string, rate: number) => {
  ///console.log(rate);
  const ves = latinFormat(rate * 0.915);

  return {
    type: SIMULATOR_ACTIONS.SET_TYPE_OF_CHANGE,
    payload: `1 ${currency} = ${ves} VES`
  };
};

//SET SAVING

const setSavingCurrency = (currency: string) => {
  return {
    type: SIMULATOR_ACTIONS.SET_SAVING_CURRENCY,
    payload: currency
  };
};

const setSaving = (value: number) => {
  return {
    type: SIMULATOR_ACTIONS.SET_SAVING,
    payload: latinFormat(value * 0.04)
  };
};

// GET YADIO RATES
export const getRates = () => {
  return (dispatch: any) => {
    axios
      .get(process.env.REACT_APP_YADIO_ENDPOINT + "/json")
      .then((response: any) => {
        dispatch({
          type: SIMULATOR_ACTIONS.GET_RATES,
          payload: {
            rates: { ...response.data.rates, USD: response.data.USD.rate }
            //amountToSendCurrency: COUNTRY_OPTIONS.slice(1)[0].countryCurrency
          }
        });
        dispatch(setInitialCountry());
      })
      .catch((error: any) => dispatch(setInitialCountry()));
  };
};
