import { combineReducers } from "redux";
// import of reducers
import SimulatorReducer, { simulatorStateType } from "./simulatorReducer";
export type globalStateType = {
  simulatorBox: simulatorStateType;
};

export default combineReducers<globalStateType>({
  simulatorBox: SimulatorReducer
});
