import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";

const HamburgerMenu = () => {
  return (
    <nav className="hamburger-menu-container">
      <div className="arrow-up"></div>
      <ul>
        <li>
          <a href="#aboutUs">Nosotros</a>
        </li>
        <li>
          <Link to="/">Blog</Link>
        </li>
        <li>
          <Link to="/">Iniciar sesión</Link>
        </li>
        <li>
          <Link to="/">Regístrate</Link>
        </li>
      </ul>
    </nav>
  );
};

export default HamburgerMenu;
