import React, { ReactElement } from "react";
import ReactGA from "react-ga";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import Landing from "../components/public-views/Landing";
import TermsAndConditions from "../components/public-views/TermsAndConditions";

import "./Main.css";

type Props = {};

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const AppRouter = (): ReactElement => {
  return (
    <Router history={history}>
      <React.Fragment>
        <div className="container">
          <Switch>
            <Route exact path="/" render={() => <Landing />} />
            <Route
              exact
              path="/terms-of-use"
              render={() => <TermsAndConditions />}
            />
          </Switch>
        </div>
      </React.Fragment>
    </Router>
  );
};

export default AppRouter;
